






























































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";
import { VueEditor } from "vue2-editor";

export default Vue.extend({
  components: {
    VueEditor,
  },
  data() {
    return {
      util,
      userManager,
      dialog: false,
      snackbar: false,
      fails: "",
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      ruleRequiredArray: (value) =>
        !!(Array.isArray(value) && value.length > 0) || this.$t("REQUIRED"),
      headers: [
        { text: "ID thông báo", value: "id" },
        { text: "Ngày thông báo", value: "notification_date" },
        { text: "Người viết", value: "created_by" },
        { text: "Đối tượng", value: "notification_object" },
        { text: "Nội dung V/v", value: "notification_title" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      options: {},
      search: {
        notification_object: "",
        notification_title: "",
        created_by: "",
          id: "",
      },
        loading: false,
        data: [],
        dataCount: 0,
        editedIndex: -1,
        editedItem: {},
        allowDelete: {},
        users: [],
        usersByRoles: [],
        userInfo: userManager.getUserInfo(),
        availableRoleMap: {
            k: this.$t("ROLE_K"),
            xnk: this.$t("ROLE_XNK"),
            kd: this.$t("ROLE_KD"),
            t: this.$t("ROLE_T"),
            kt: this.$t("ROLE_KT"),
            td: this.$t("ROLE_TD"),
            gd: this.$t("ROLE_GD"),
            htkd: this.$t("ROLE_HTKD"),
            cs: "Chính sách",
            kvn: "Kho VN",
            ktt: "Kế toán trưởng",
            mkt: "Marketing",
        },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tạo thông báo" : this.$t("EDIT");
    },
      availableProgresses() {
          const items = [];
          for (const k in this.availableProgressMap) {
              items.push({
                  text: this.availableProgressMap[k],
                  value: k,
              });
          }
          return items;
      },
      availableRoles() {
          const items = [];
          for (const k in this.availableRoleMap) {
              items.push({
                  text: this.availableRoleMap[k],
                  value: k,
              });
          }
          return items;
      },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      const filters = [];
      const search = { ...this.search };
      for (const searchKey in search) {
        if (search[searchKey]) {
          if (searchKey === "id") {
            search[searchKey] = search[searchKey].replace("TB", "");
          }
          if (searchKey === "notification_title" || searchKey === "notification_object") {
              filters.push({
                  key: searchKey,
                  operator: "LIKE",
                  value: `%${search[searchKey]}%`,
              });
          } else {
              filters.push({
                  key: searchKey,
                  operator: "=",
                  value: search[searchKey],
              });
          }
        }
      }
      this.loading = true;
      const { items, count } = await apiClient.notificationGetAll({
        options: this.options,
        filters,
      });
      this.data = items.map((item) => {
          const seen = new Set();
          item.users = item.users.filter(el => {
              const duplicate = seen.has(el.id);
              seen.add(el.id);
              return !duplicate;
          });
          return item;
      });
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
        console.log(this.editedItem.notification_object)
      if (!Array.isArray(this.editedItem.notification_object)) {
        this.editedItem.notification_object = [
          this.editedItem.notification_object,
        ];
      } else {
          this.editedItem.notification_object = [...new Set(this.editedItem.notification_object)];
      }
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      await apiClient.notificationDelete(this.editedItem);
      await this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let result;
      const userInfo = { ...this.userManager.getUserInfo() };
      if (userInfo.sub) {
        this.editedItem["created_by"] = userInfo.sub;
      }
      if (
        this.editedItem.notification_object &&
        Array.isArray(this.editedItem.notification_object) &&
        this.editedItem.notification_object.length > 0
      ) {
          this.editedItem.notification_object =
              this.editedItem.notification_object.filter((id) => id !== "all" && id !== null);
      } else {
          delete this.editedItem.notification_object
      }
      if (Array.isArray(this.editedItem.roles) && this.editedItem.roles.length === 0) {
          delete this.editedItem.roles
      }
      if (this.editedIndex > -1) {
        result = await apiClient.notificationUpdate(this.editedItem);
      } else {
        result = await apiClient.notificationCreate(this.editedItem);
      }
      if (!result.errors) {
        this.close();
        await this.initialize();
      } else {
        this.snackbar = true;
        this.fails = result.message;
      }
    },
    getAllUser() {
      this.usersByRoles = [];
      apiClient
        .userGetAll()
        .then((res) => {
          this.users = [
            {
              id: "all",
              name: "Tất cả",
            },
          ].concat(
            res.items.map((item) => {
              item.id = String(item.id);
              return item;
            })
          );
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
    getName(value, data, keyData = "id", keyName = "name") {
      if (value) {
        if (Array.isArray(data)) {
          const length = data.length;
          for (let i = 0; i < length; i++) {
            if (data[i][keyData] === value) {
              return data[i][keyName];
            }
          }
        }
        return value;
      }
      return "";
    },
    goToDetailsObject(record) {
      console.log(record);
    },
    onChangeObject(val) {
      const isAll = val.includes("all");
      if (isAll) {
        this.editedItem.notification_object = this.users.map((item) => {
          return item.id;
        });
        this.users.shift();
        this.users.unshift({
          name: "Bỏ chọn",
          id: "removeAll",
        });
      }
      if (val.includes("removeAll")) {
        this.editedItem.notification_object = [];
        this.users.shift();
        this.users.unshift({
          name: "Tất cả",
          id: "all",
        });
      }
    },
  },
  created() {
    this.getAllUser();
  },
});
